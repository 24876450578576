import React, { useState, useEffect, useRef, useCallback } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { cn } from '@/lib/utils'
import { IconDotsVertical } from '@tabler/icons-react'
import { ImageKit, MotionImageKit } from '@/components/global/ImageKit'

interface CompareProps {
    firstImage?: string
    secondImage?: string
    className?: string
    firstImageClassName?: string
    secondImageClassName?: string
    initialSliderPercentage?: number
    slideMode?: 'hover' | 'drag'
    showHandlebar?: boolean
    autoplay?: boolean
    autoplayDuration?: number
    firstFilters?: React.ReactNode[]
    secondFilters?: React.ReactNode[]
}
export const Compare = ({
    firstImage = '',
    secondImage = '',
    firstFilters = [],
    secondFilters = [],
    className,
    firstImageClassName,
    secondImageClassName,
    initialSliderPercentage = 50,
    slideMode = 'hover',
    showHandlebar = true,
    autoplay = false,
    autoplayDuration = 2000,
}: CompareProps) => {
    const [sliderXPercent, setSliderXPercent] = useState(
        initialSliderPercentage,
    )
    const [isDragging, setIsDragging] = useState(false)

    const sliderRef = useRef<HTMLDivElement>(null)

    const [isMouseOver, setIsMouseOver] = useState(false)

    const autoplayRef = useRef<NodeJS.Timeout | null>(null)

    const startAutoplay = useCallback(() => {
        if (!autoplay) return

        const startTime = Date.now()
        const animate = () => {
            const elapsedTime = Date.now() - startTime
            const progress =
                (elapsedTime % (autoplayDuration * 2)) / autoplayDuration
            const percentage =
                progress <= 1 ? progress * 100 : (2 - progress) * 100

            setSliderXPercent(percentage)
            autoplayRef.current = setTimeout(animate, 16) // ~60fps
        }

        animate()
    }, [autoplay, autoplayDuration])

    const stopAutoplay = useCallback(() => {
        if (autoplayRef.current) {
            clearTimeout(autoplayRef.current)
            autoplayRef.current = null
        }
    }, [])

    useEffect(() => {
        startAutoplay()
        return () => stopAutoplay()
    }, [startAutoplay, stopAutoplay])

    function mouseEnterHandler() {
        setIsMouseOver(true)
        stopAutoplay()
    }

    function mouseLeaveHandler() {
        setIsMouseOver(false)
        if (slideMode === 'hover') {
            setSliderXPercent(initialSliderPercentage)
        }
        if (slideMode === 'drag') {
            setIsDragging(false)
        }
        startAutoplay()
    }

    const handleStart = useCallback(
        (clientX: number) => {
            if (slideMode === 'drag') {
                setIsDragging(true)
            }
        },
        [slideMode],
    )

    const handleEnd = useCallback(() => {
        if (slideMode === 'drag') {
            setIsDragging(false)
        }
    }, [slideMode])

    const handleMove = useCallback(
        (clientX: number) => {
            if (!sliderRef.current) return
            if (slideMode === 'hover' || (slideMode === 'drag' && isDragging)) {
                const rect = sliderRef.current.getBoundingClientRect()
                const x = clientX - rect.left
                const percent = (x / rect.width) * 100
                requestAnimationFrame(() => {
                    setSliderXPercent(Math.max(0, Math.min(100, percent)))
                })
            }
        },
        [slideMode, isDragging],
    )

    const handleMouseDown = useCallback(
        (e: React.MouseEvent) => handleStart(e.clientX),
        [handleStart],
    )
    const handleMouseUp = useCallback(() => handleEnd(), [handleEnd])
    const handleMouseMove = useCallback(
        (e: React.MouseEvent) => handleMove(e.clientX),
        [handleMove],
    )

    const handleTouchStart = useCallback(
        (e: React.TouchEvent) => {
            if (!autoplay) {
                handleStart(e.touches[0].clientX)
            }
        },
        [handleStart, autoplay],
    )

    const handleTouchEnd = useCallback(() => {
        if (!autoplay) {
            handleEnd()
        }
    }, [handleEnd, autoplay])

    const handleTouchMove = useCallback(
        (e: React.TouchEvent) => {
            if (!autoplay) {
                handleMove(e.touches[0].clientX)
            }
        },
        [handleMove, autoplay],
    )

    return (
        <>
            <div
                ref={sliderRef}
                className={cn('h-full w-full overflow-hidden', className)}
                style={{
                    position: 'relative',
                    cursor: slideMode === 'drag' ? 'grab' : 'col-resize',
                }}
                onMouseMove={handleMouseMove}
                onMouseLeave={mouseLeaveHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
            >
                <AnimatePresence initial={false}>
                    <motion.div
                        className='absolute top-0 z-[50] m-auto h-full w-px bg-white'
                        style={{
                            left: `${sliderXPercent}%`,
                            top: '0',
                            zIndex: 50,
                        }}
                        transition={{ duration: 0 }}
                    >
                        {showHandlebar && (
                            <div className='absolute -right-1.5 z-[50] top-1/2 flex h-5 w-3 -translate-y-1/2 items-center justify-center rounded-sm bg-white shadow-[0px_-1px_0px_0px_#FFFFFF40]'>
                                <IconDotsVertical className='h-4 w-3 text-black' />
                            </div>
                        )}
                    </motion.div>
                </AnimatePresence>
                <div className='pointer-events-none relative z-40 h-full w-full overflow-hidden'>
                    <div className='h-full w-full'>
                        <AnimatePresence initial={false}>
                            {firstImage ? (
                                <motion.div
                                    className={cn(
                                        'absolute inset-0 z-20 h-full w-full flex-shrink-0 select-none overflow-hidden',
                                        firstImageClassName,
                                    )}
                                    style={{
                                        clipPath: `inset(0 ${100 - sliderXPercent}% 0 0)`,
                                    }}
                                    transition={{ duration: 0 }}
                                >
                                    <ImageKit
                                        id='left-image'
                                        alt='first image'
                                        src={firstImage}
                                        loading='lazy'
                                        transformation={[
                                            {
                                                width: 750,
                                            },
                                            {
                                                quality: 50,
                                            },
                                        ]}
                                        className={cn(
                                            'absolute inset-0 z-80 h-full w-full flex-shrink-0 select-none opacity-100',
                                            firstImageClassName,
                                        )}
                                        draggable={false}
                                    />
                                    <div
                                        id='filter-container'
                                        className='isolate absolute w-full h-full left-0 z-30'
                                    >
                                        {firstFilters.map((filter, index) => (
                                            <React.Fragment key={index}>
                                                {filter}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </motion.div>
                            ) : null}
                        </AnimatePresence>
                    </div>
                </div>

                <AnimatePresence initial={false}>
                    {secondImage ? (
                        <>
                            <MotionImageKit
                                className={cn(
                                    'absolute left-0 top-0 z-[19] h-full w-full select-none opacity-100',
                                    secondImageClassName,
                                )}
                                alt='second image'
                                src={secondImage}
                                loading='lazy'
                                transformation={[
                                    {
                                        width: 750,
                                    },
                                    {
                                        quality: 50,
                                    },
                                ]}
                                draggable={false}
                            />
                            <div
                                id='filter-container-b'
                                className='isolate absolute left-0 top-0 w-full h-full z-30'
                            >
                                {secondFilters.map((filter, index) => (
                                    <React.Fragment key={index}>
                                        {filter}
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                    ) : null}
                </AnimatePresence>
            </div>
        </>
    )
}
