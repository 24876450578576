import RenderRaceTabs from '@/components/blocks/render-race/RenderRaceTabs'
import BrowserFrame from '@/components/renderjuice/BrowserFrame'
import { LampContainer } from '@/components/renderjuice/Lamp'
import { AnimatePresence } from 'framer-motion'
import { useIntervalWhenControl } from '@/lib/hooks'
import { useInView } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'
import { Status, Timer } from '@/components/blocks/render-race/Statuses'
import {
    CheckersFilter,
    NoiseFilter,
} from '@/components/blocks/render-race/Filters'

import { ShinedVersusText } from '@/components/renderjuice/Lamp'
import { Compare } from '@/components/ui/compare'
import {
    useAnimate,
    motion,
    type AnimationPlaybackControls,
} from 'framer-motion'
import { formatElapsedTime } from '@/components/blocks/render-race/utils'

const formatSavedTime = (s: number, options?: { fullText?: boolean }) => {
    const seconds = Math.floor(s % 60)
    const minutes = Math.floor((s % 3600) / 60)
    const hours = Math.floor(s / 3600)

    if (options?.fullText) {
        return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`
    } else {
        const pad = (n: number) => n.toString().padStart(1, '0')
        return `${pad(hours)}hr${hours > 0 ? 's' : ''} ${pad(minutes)}m ${pad(seconds)}s`
    }
}

// Type guard function
function isAnimationPlaybackControls(
    control: any,
): control is AnimationPlaybackControls {
    return (
        control !== null &&
        typeof control === 'object' &&
        'play' in control &&
        'pause' in control &&
        'stop' in control &&
        'cancel' in control
    )
}

const totalRenderTimes = {
    classroom: {
        aSecs: 43531,
        bSecs: 2220,
    },
    barbershop: {
        aSecs: 60670,
        bSecs: 2665,
    },
    barcelona: {
        aSecs: 4555,
        bSecs: 320,
    },
}

const DENOISE_SPLIT = 0.25
const RAYTRACE_SPLIT = 0.75
const PLAYBACK_SPEED = 60

const totalTimeToAnimationDurations = (totalTime: number) => {
    const raytraceTime = (totalTime * RAYTRACE_SPLIT) / PLAYBACK_SPEED
    const denoiseTime = (totalTime * DENOISE_SPLIT) / PLAYBACK_SPEED

    return [raytraceTime, denoiseTime]
}

const calculateSpeedUpMultiple = (timeTotalA: number, timeTotalB: number) => {
    const multiple = timeTotalA / timeTotalB
    return Math.round(multiple).toFixed(0)
}

const calculatePercentageIncrease = (
    timeTotalA: number,
    timeTotalB: number,
) => {
    const percentageIncrease = (
        (parseFloat(calculateSpeedUpMultiple(timeTotalA, timeTotalB)) - 1) *
        100
    ).toFixed(2)
    return percentageIncrease
}

const calculateTimeSaved = (timeTotalA: number, timeTotalB: number) => {
    const timeSaved = timeTotalA - timeTotalB
    return formatSavedTime(timeSaved, { fullText: true })
}

const calculateTimeToComplete = (timeTotal: number) => {
    const timeToComplete = timeTotal * 1000 // Convert to milliseconds
    return formatElapsedTime(timeToComplete, true)
}

const calculatePercentageSaved = (timeTotalA: number, timeTotalB: number) => {
    const percentageSaved = ((timeTotalA - timeTotalB) / timeTotalA) * 100
    return percentageSaved.toFixed(2)
}

const barcelonaRenderRaceConfig = {
    durationsA: totalTimeToAnimationDurations(totalRenderTimes.barcelona.aSecs),
    durationsB: totalTimeToAnimationDurations(totalRenderTimes.barcelona.bSecs),
    timeDifference: calculateTimeSaved(
        totalRenderTimes.barcelona.aSecs,
        totalRenderTimes.barcelona.bSecs,
    ),
    speedUpMultiple: calculateSpeedUpMultiple(
        totalRenderTimes.barcelona.aSecs,
        totalRenderTimes.barcelona.bSecs,
    ),
    timeToCompleteA: calculateTimeToComplete(totalRenderTimes.barcelona.aSecs),
    timeToCompleteB: calculateTimeToComplete(totalRenderTimes.barcelona.bSecs),
    tabTitle: 'Barcelona Pavilion',
    imagePath: '/renders/barcelona.webp',
}
const barberShopRenderRaceConfig = {
    durationsA: totalTimeToAnimationDurations(
        totalRenderTimes.barbershop.aSecs,
    ),
    durationsB: totalTimeToAnimationDurations(
        totalRenderTimes.barbershop.bSecs,
    ),
    timeDifference: calculateTimeSaved(
        totalRenderTimes.barbershop.aSecs,
        totalRenderTimes.barbershop.bSecs,
    ),
    speedUpMultiple: calculateSpeedUpMultiple(
        totalRenderTimes.barbershop.aSecs,
        totalRenderTimes.barbershop.bSecs,
    ),
    timeToCompleteA: calculateTimeToComplete(totalRenderTimes.barbershop.aSecs),
    timeToCompleteB: calculateTimeToComplete(totalRenderTimes.barbershop.bSecs),
    tabTitle: 'Barber Shop',
    imagePath: '/renders/barbershop.webp',
}

const classroomRenderConfig = {
    durationsA: totalTimeToAnimationDurations(totalRenderTimes.classroom.aSecs),
    durationsB: totalTimeToAnimationDurations(totalRenderTimes.classroom.bSecs),
    timeDifference: calculateTimeSaved(
        totalRenderTimes.classroom.aSecs,
        totalRenderTimes.classroom.bSecs,
    ),
    speedUpMultiple: calculateSpeedUpMultiple(
        totalRenderTimes.classroom.aSecs,
        totalRenderTimes.classroom.bSecs,
    ),
    timeToCompleteA: calculateTimeToComplete(totalRenderTimes.classroom.aSecs),
    timeToCompleteB: calculateTimeToComplete(totalRenderTimes.classroom.bSecs),
    tabTitle: 'Classroom',
    imagePath: '/renders/classroom.webp',
}

const raceConfigs = [
    barberShopRenderRaceConfig,
    barcelonaRenderRaceConfig,
    classroomRenderConfig,
]

const getAnimationDurations = (index: number) => {
    const { durationsA, durationsB } = raceConfigs[index]
    return {
        durationsA,
        durationsB,
    }
}

const checkAllRefsSet = (refs: any[]) => {
    return refs.every((el) => el !== null)
}

const FPS = 20
const updateIntervalInMs = 1000 / FPS

type AnimationStatus = 'raytracing' | 'denoising' | 'complete'

export default function RenderRaceSection() {
    const initialAnimationState = 'raytracing'
    const [animationStateA, setAnimationStateA] = useState<AnimationStatus>(
        initialAnimationState,
    )
    const [animationStateB, setAnimationStateB] = useState<AnimationStatus>(
        initialAnimationState,
    )
    const { handleClearInterval, startTimer, stopTimer, time } =
        useIntervalWhenControl({ multiple: PLAYBACK_SPEED, updateIntervalInMs })

    const controlsRaytraceA = useRef<AnimationPlaybackControls | null>(null)
    const controlsRaytraceB = useRef<AnimationPlaybackControls | null>(null)
    const controlsDenoiseA = useRef<AnimationPlaybackControls | null>(null)
    const controlsDenoiseB = useRef<AnimationPlaybackControls | null>(null)

    const [activeIndex, setActiveIndex] = useState(0)
    const [raytraceScopeA, raytraceAnimateA] = useAnimate()
    const [denoiseScopeA, denoiseAnimateA] = useAnimate()
    const [raytraceScopeB, raytraceAnimateB] = useAnimate()
    const [denoiseScopeB, denoiseAnimateB] = useAnimate()

    const cleanUpRace = () => {
        if (
            !checkAllRefsSet([
                controlsRaytraceA.current,
                controlsRaytraceB.current,
                controlsDenoiseA.current,
                controlsDenoiseB.current,
            ])
        ) {
            throw new Error('Invalid animation state')
        }

        // Check if controls are of the expected type
        if (
            !isAnimationPlaybackControls(controlsRaytraceA.current) ||
            !isAnimationPlaybackControls(controlsRaytraceB.current) ||
            !isAnimationPlaybackControls(controlsDenoiseA.current) ||
            !isAnimationPlaybackControls(controlsDenoiseB.current)
        ) {
            throw new Error(
                'One or more controls are not of type AnimationPlaybackControls',
            )
        }

        stopTimer()
        setAnimationStateA(initialAnimationState)
        setAnimationStateB(initialAnimationState)
        controlsRaytraceA.current.cancel()
        controlsRaytraceB.current.cancel()
        controlsDenoiseA.current.cancel()
        controlsDenoiseB.current.cancel()

        // Nullify controls
        controlsRaytraceA.current = null
        controlsRaytraceB.current = null
        controlsDenoiseA.current = null
        controlsDenoiseB.current = null
    }

    const startRace = (index: number) => {
        if (
            checkAllRefsSet([
                controlsRaytraceA.current,
                controlsRaytraceB.current,
                controlsDenoiseA.current,
                controlsDenoiseB.current,
            ])
        ) {
            console.log('cleaning up race')
            cleanUpRace()
        }

        const { durationsA, durationsB } = getAnimationDurations(index)
        const [raytraceDurationA, denoiseDurationA] = durationsA
        const [raytraceDurationB, denoiseDurationB] = durationsB
        controlsRaytraceA.current = raytraceAnimateA(
            raytraceScopeA.current,
            { opacity: [1, 0] },
            {
                duration: raytraceDurationA,
                onComplete: () => {
                    setAnimationStateA('denoising')
                },
            },
        )
        controlsRaytraceB.current = raytraceAnimateB(
            raytraceScopeB.current,
            { opacity: [1, 0] },
            {
                duration: raytraceDurationB,
                onComplete: () => {
                    setAnimationStateB('denoising')
                },
            },
        )
        controlsDenoiseA.current = denoiseAnimateA(
            denoiseScopeA.current,
            { opacity: [1, 0] },
            {
                duration: denoiseDurationA,
                delay: raytraceDurationA,
                onComplete: () => {
                    console.log('stopping timer')
                    setAnimationStateA('complete')
                    handleClearInterval()
                    stopTimer()
                },
            },
        )
        controlsDenoiseB.current = denoiseAnimateB(
            denoiseScopeB.current,
            { opacity: [1, 0] },
            {
                duration: denoiseDurationB,
                delay: raytraceDurationB,
                onComplete: () => {
                    setAnimationStateB('complete')
                },
            },
        )

        startTimer()
        controlsRaytraceA.current.play()
        controlsRaytraceB.current.play()
        controlsDenoiseA.current.play()
        controlsDenoiseB.current.play()
    }

    const onSwitchTab = (index: number) => {
        setActiveIndex(index)
    }

    const statusA = animationStateA
    const statusB = animationStateB

    const viewportRef = useRef<HTMLDivElement>(null)
    const isInView = useInView(viewportRef, { amount: 1, once: true })

    // Triggers animation only once the stats are in view
    useEffect(() => {
        if (!isInView) return
        startRace(activeIndex)
    }, [isInView, activeIndex])
    return (
        <section className='relative mb-12'>
            <LampContainer>
                <div className='z-20 flex w-full flex-col px-4 md:max-w-5xl md:px-12 mb-8 md:mb-4'>
                    <ShinedVersusText />
                    <BrowserFrame>
                        <Compare
                            firstImage={raceConfigs[activeIndex].imagePath}
                            firstFilters={[
                                <CheckersFilter
                                    id='checkers-filter-a'
                                    ref={raytraceScopeA}
                                    key='a'
                                />,
                                <NoiseFilter
                                    id='noise-filter-a'
                                    ref={denoiseScopeA}
                                    key='a'
                                />,
                            ]}
                            secondImage={raceConfigs[activeIndex].imagePath}
                            secondFilters={[
                                <CheckersFilter
                                    id='checkers-filter-b'
                                    ref={raytraceScopeB}
                                    key='b'
                                />,
                                <NoiseFilter
                                    id='noise-filter-b'
                                    ref={denoiseScopeB}
                                    key='b'
                                />,
                            ]}
                            initialSliderPercentage={50}
                            key={`compare-${activeIndex}`}
                        />
                    </BrowserFrame>
                </div>
                <motion.div
                    ref={viewportRef}
                    className='md:max-w-5xl md:px-12 relative bottom-0 left-1/2 min-h-[200px] min-w-[100px] -translate-x-1/2 z-10'
                >
                    <div className='flex flex-col gap-0'>
                        <div className='flex justify-between -mt-2 mb-8 w-full'>
                            <div className='w-1/3 flex justify-start'>
                                <Status
                                    left={true}
                                    status={statusA}
                                    timeToComplete={
                                        raceConfigs[activeIndex].timeToCompleteA
                                    }
                                    key={`a-${statusA}`}
                                />
                            </div>
                            <div className='w-1/3 flex flex-col items-center'>
                                <Timer time={time ?? 0} />
                            </div>
                            <div className='w-1/3 flex justify-end'>
                                <Status
                                    left={false}
                                    status={statusB}
                                    timeToComplete={
                                        raceConfigs[activeIndex].timeToCompleteB
                                    }
                                    key={`b-${statusB}`}
                                />
                            </div>
                        </div>
                        <h2 className='x-text-gradient-metal-down text-center text-4xl font-medium mb-2'>
                            {raceConfigs[activeIndex].speedUpMultiple}X Faster
                        </h2>
                        <h2 className='x-text-gradient-hybrid-metal-right text-center text-xl text-white mb-6'>
                            {raceConfigs[activeIndex].timeDifference} saved
                        </h2>
                    </div>
                    <RenderRaceTabs
                        tabs={raceConfigs.map((config) => config.tabTitle)}
                        onTabChange={onSwitchTab}
                    />
                </motion.div>
            </LampContainer>
        </section>
    )
}
