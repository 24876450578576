import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'

interface BrowserFrameProps {
    children: React.ReactNode
}

const BrowserFrame = forwardRef<HTMLDivElement, BrowserFrameProps>(
    ({ children }, ref) => {
        return (
            <motion.div className='w-full max-w-2xl isolate' ref={ref}>
                <div className='flex h-5 items-center justify-start space-x-1.5 rounded-t-md bg-neutral-800/80 px-3 z-20'>
                    <span className='h-1.5 w-1.5 rounded-full bg-red-400/90' />
                    <span className='h-1.5 w-1.5 rounded-full bg-yellow-400/90' />
                    <span className='h-1.5 w-1.5 rounded-full bg-green-400/90' />
                </div>
                <div className='h-[200px] md:h-[350px] md:w-[750px] lg:w-[800px] max-w-2xl border-t-0 bg-gray-400'>
                    {children}
                </div>
            </motion.div>
        )
    },
)

BrowserFrame.displayName = 'BrowserFrame'

export default BrowserFrame
