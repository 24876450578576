/***
 * Probably refactor the animation timers first
 * * consider using framer-motion wholly instead
 * * if not possible restructure components to minimize renders
 * * isolate each filter to use the timer to prevent whole tree rerenders
 * * reorganize timer so everything starts at the same time and is in sync.
 * * make the timers stack implicitly so opacity of 1 timer doesnt start before the first has even finished
 * * set the elapsed timer to work properly
 * * take the difference of each phase
 * * make the slider pop on top of the filters and image comparison
 */

import React, {
    useState,
    useLayoutEffect,
    useEffect,
    useRef,
    useCallback,
} from 'react'
import { useEffectEvent } from 'use-effect-event'

/**
 * Simple animation hook
 * TODO: optimize this hook or offload to CSS to reduce blinking.
 */
const useAnimationTimer = (totalDuration: number = 3000) => {
    const [percentage, setPercentage] = useState(0)
    const startTimeRef = useRef<number | null>(null)

    useEffect(() => {
        const animate = (timestamp: number) => {
            if (startTimeRef.current === null) {
                startTimeRef.current = timestamp
            }

            const elapsed = timestamp - startTimeRef.current
            const newPercentage = Math.min(elapsed / totalDuration, 1)

            setPercentage(newPercentage)

            if (newPercentage < 100) {
                requestAnimationFrame(animate)
            }
        }

        requestAnimationFrame(animate)

        return () => {
            startTimeRef.current = null
        }
    }, [totalDuration])
    console.count()

    return percentage
}

/**
 * This improved animation timer will only rerender every threshold% update.
 * use `console.count` as a nice way to count rerenders fairly easily
 */
const useAnimationTimerV2 = (
    totalDuration: number = 3000,
    threshold: number = 0.01,
) => {
    const [percentage, setPercentage] = useState(0)
    const startTimeRef = useRef<number | null>(null)
    const animationFrameRef = useRef<number | null>(null)

    useEffect(() => {
        const animate = (timestamp: number) => {
            if (startTimeRef.current === null) {
                startTimeRef.current = timestamp
            }

            const elapsed = timestamp - startTimeRef.current
            const newPercentage = Math.min(elapsed / totalDuration, 1)

            if (Math.abs(newPercentage - percentage) >= threshold) {
                setPercentage(newPercentage)
            }

            if (newPercentage < 1) {
                animationFrameRef.current = requestAnimationFrame(animate)
            }
        }

        animationFrameRef.current = requestAnimationFrame(animate)

        return () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current)
            }
            startTimeRef.current = null
        }
    }, [totalDuration, percentage, threshold])

    return percentage
}

const useSequentialAnimationTimers = (durations: number[]) => {
    const [currentTimerIndex, setCurrentTimerIndex] = useState(0)
    const [percentages, setPercentages] = useState<number[]>(
        new Array(durations.length).fill(0),
    )

    const percentage = useAnimationTimer(durations[currentTimerIndex])

    useEffect(() => {
        if (percentage === 1 && currentTimerIndex < durations.length - 1) {
            setCurrentTimerIndex((prevIndex) => prevIndex + 1)
        }

        setPercentages((prev) => {
            const newPercentages = [...prev]
            newPercentages[currentTimerIndex] = percentage
            return newPercentages
        })
    }, [percentage, currentTimerIndex, durations.length])

    return percentages
}

const useAnimationTimerV3 = (
    totalDuration: number = 3000,
    threshold: number = 0.005,
    key?: any,
) => {
    const [percentage, setPercentage] = useState(0)
    const startTimeRef = useRef<number | null>(null)
    const animationFrameRef = useRef<number | null>(null)
    const lastPercentageRef = useRef(0)

    useEffect(() => {
        setPercentage(0)
        startTimeRef.current = null
        lastPercentageRef.current = 0

        const animate = (timestamp: number) => {
            if (startTimeRef.current === null) {
                startTimeRef.current = timestamp
            }

            const elapsed = timestamp - startTimeRef.current
            const newPercentage = Math.min(elapsed / totalDuration, 1)

            if (
                Math.abs(newPercentage - lastPercentageRef.current) >= threshold
            ) {
                lastPercentageRef.current = newPercentage
                setPercentage(newPercentage)
            }

            if (newPercentage < 1) {
                animationFrameRef.current = requestAnimationFrame(animate)
            }
        }

        animationFrameRef.current = requestAnimationFrame(animate)

        return () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current)
            }
            startTimeRef.current = null
        }
    }, [totalDuration, threshold, key])

    return percentage
}

const useSequentialAnimationTimersV3 = (
    durations: number[],
    threshold: number = 0.02,
    cacheKey: number,
) => {
    const [currentTimerIndex, setCurrentTimerIndex] = useState(0)
    const [percentages, setPercentages] = useState<number[]>(
        new Array(durations.length).fill(0),
    )

    useEffect(() => {
        setCurrentTimerIndex(0)
        setPercentages(new Array(durations.length).fill(0))
    }, [cacheKey])

    const percentage = useAnimationTimerV3(
        durations[currentTimerIndex],
        threshold,
    )

    useEffect(() => {
        if (percentage >= 1 && currentTimerIndex < durations.length - 1) {
            setCurrentTimerIndex((prevIndex) => prevIndex + 1)
        }

        setPercentages((prev) => {
            const newPercentages = [...prev]
            newPercentages[currentTimerIndex] = percentage
            return newPercentages
        })
    }, [percentage, currentTimerIndex, durations.length])

    return percentages
}

const useSequentialAnimationTimersV4 = (
    durations: number[],
    threshold: number = 0.02,
    key?: number,
) => {
    const [currentTimerIndex, setCurrentTimerIndex] = useState(0)
    const [percentages, setPercentages] = useState<number[]>(
        new Array(durations.length).fill(0),
    )

    const percentage = useAnimationTimerV3(
        durations[currentTimerIndex],
        threshold,
    )

    useEffect(() => {
        setPercentages(durations.map(() => 0))
        setPercentages((prev) => {
            const newPercentages = [...prev]
            newPercentages[currentTimerIndex] = percentage
            return newPercentages
        })

        if (percentage === 1 && currentTimerIndex < durations.length - 1) {
            setCurrentTimerIndex((prevIndex) => prevIndex + 1)
        }
    }, [percentage, currentTimerIndex, durations.length, key])

    return percentages
}

type RaceConfig = Array<{
    durationsA: number[]
    durationsB: number[]
    refsA: React.RefObject<HTMLDivElement>[]
    refsB: React.RefObject<HTMLDivElement>[]
}>

interface RenderRaceResult {
    startAnimation: () => void
    stopAnimation: () => void
    setActiveIndex: (index: number) => void
    activeIndex: number
    elapsed: number
    statusA: string
    statusB: string
}

const useRenderRaceAnimation = (raceConfig: RaceConfig): RenderRaceResult => {
    const [index, setIndex] = useState(0)
    const raytraceDurationA = raceConfig[index].durationsA[0]
    const raytraceDurationB = raceConfig[index].durationsB[0]
    const denoiseDurationA = raceConfig[index].durationsA[1]
    const denoiseDurationB = raceConfig[index].durationsB[1]
    const intervalRef = useRef(null)
    const checkersRefA = raceConfig[index].refsA[0]
    const checkersRefB = raceConfig[index].refsB[0]
    const noiseRefA = raceConfig[index].refsA[1]
    const noiseRefB = raceConfig[index].refsB[1]
    const startTimeRef = useRef(0)
    const [isRunning, setIsRunning] = useState(false)
    const [elapsed, setElapsed] = useState(0)
    /**
     * While the animation is an approximation due to local
     * machine performance of CSS & JS execution.
     * The numbers displayed should be as accurate as possible.
     */
    const statusA =
        elapsed < raytraceDurationA
            ? 'Raytracing'
            : elapsed < raytraceDurationA + denoiseDurationA
              ? 'Denoising'
              : 'Complete'
    const statusB =
        elapsed < raytraceDurationB
            ? 'Raytracing'
            : elapsed < raytraceDurationB + denoiseDurationB
              ? 'Denoising'
              : 'Complete'

    useEffect(() => {
        if (!isRunning) {
            clearInterval(intervalRef.current)
            clearInterval(startTimeRef.current)
            return
        }
        startTimeRef.current = Date.now() - elapsed
        intervalRef.current = window.setInterval(() => {
            const newElapsed = Date.now() - startTimeRef.current
            setElapsed(newElapsed)
            if (newElapsed >= raytraceDurationA && noiseRefA.current) {
                noiseRefA.current.classList.add('denoise-a')
            }
            if (newElapsed >= raytraceDurationB && noiseRefB.current) {
                noiseRefB.current.classList.add('denoise-b')
            }
        }, 16)
        if (statusA === 'Complete' && statusB === 'Complete') {
            setIsRunning(false)
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
            if (startTimeRef.current) {
                clearInterval(startTimeRef.current)
            }
        }
    }, [isRunning, elapsed])

    const startAnimation = () => {
        if (
            !checkersRefA.current ||
            !checkersRefB.current ||
            !noiseRefA.current ||
            !noiseRefB.current
        ) {
            return
            console.error('Refs are not set')
        }
        // Re-add classes to restart animations
        noiseRefA.current.classList.remove('denoise-a')
        noiseRefB.current.classList.remove('denoise-b')
        checkersRefA.current.classList.remove('raytrace-a')
        checkersRefA.current.classList.add('raytrace-a')
        checkersRefB.current.classList.remove('raytrace-b')
        checkersRefB.current.classList.add('raytrace-b')
        setElapsed(0)
        setIsRunning(true)
    }

    const stopAnimation = () => {
        // Stop the animation
        setIsRunning(false)
        // Reset the Timer
        setElapsed(0)
        // Clean Up
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        // Remove the classes from the animation
        checkersRefA.current?.classList.remove('raytrace-a', 'denoise-a')
        noiseRefA.current?.classList.remove('raytrace-a', 'denoise-a')
        checkersRefB.current?.classList.remove('raytrace-b', 'denoise-b')
        noiseRefB.current?.classList.remove('raytrace-b', 'denoise-b')
    }

    const setIndexHandler = (nextIndex: number) => {
        stopAnimation()
        setIndex((prevIndex) => {
            if (prevIndex === nextIndex) {
                return prevIndex
            }
            return nextIndex
        })
        /**
         * because React's DOM sync is different than CSS's DOM sync
         * we cannot call startAnimation() here
         * without ensuring the DOM has been updated via `void offsetWidth`
         * because the DOM may not have been updated yet completely.
         */
        void checkersRefA.current?.offsetWidth
        void checkersRefB.current?.offsetWidth
        void noiseRefA.current?.offsetWidth
        void noiseRefB.current?.offsetWidth
        startAnimation()
    }

    return {
        activeIndex: index,
        setActiveIndex: setIndexHandler,
        startAnimation,
        stopAnimation,
        elapsed,
        statusA,
        statusB,
    }
}

function useIntervalWhen(
    cb: () => void,
    {
        ms,
        when,
        startImmediately,
    }: { ms: number; when: boolean; startImmediately: boolean },
) {
    const id = useRef(null)
    const onTick = useEffectEvent(cb)
    const immediatelyCalled = useRef(startImmediately === true ? false : null)

    const handleClearInterval = useCallback(() => {
        if (id.current) {
            window.clearInterval(id.current)
        }
        immediatelyCalled.current = false
    }, [])

    useEffect(() => {
        if (when === true) {
            id.current = window.setInterval(onTick, ms)

            if (
                startImmediately === true &&
                immediatelyCalled.current === false
            ) {
                onTick()
                immediatelyCalled.current = true
            }

            return handleClearInterval
        }
    }, [ms, when, startImmediately, handleClearInterval])

    return handleClearInterval
}

/**
 * @param multiple - the playback speed - when multiple is 5, every second is equal to five seconds elapse.
 * @param updateIntervalInMs - the interval at which the timer is updated in the UI
 */
function useIntervalWhenControl({ multiple = 10, updateIntervalInMs = 1000 }: { multiple: number, updateIntervalInMs: number }) {
    const [isRunning, setIsRunning] = useState(false)
    const createInterval = () => {
        intervalRef.current = window.setInterval(() => {
            setTime((Date.now() - startTimeRef.current) * multiple)
        }, updateIntervalInMs)
    }
    const startTimer = () => {
        setIsRunning(true)
        createInterval()
        startTimeRef.current = Date.now()
        setTime(0)
    }
    const pauseTimer = () => {
        setIsRunning(false)
        handleClearInterval()
    }
    const stopTimer = () => {
        setIsRunning(false)
        handleClearInterval()
    }
    const intervalRef = useRef<number | null>(null)
    const startTimeRef = useRef(0)
    const [time, setTime] = useState<null | number>(0)

    const handleClearInterval = useCallback(() => {
        if (intervalRef.current) {
            console.log('clearing intervalref')
            window.clearInterval(intervalRef.current)
        }
        if (startTimeRef.current) {
            console.log('clearing starttimeref')
            window.clearInterval(startTimeRef.current)
        }
    }, [])


    useEffect(() => {
        if (isRunning) {
            startTimeRef.current = Date.now()
            createInterval()
            return handleClearInterval
        }
        handleClearInterval()
        return handleClearInterval
    }, [isRunning, handleClearInterval, multiple, updateIntervalInMs])

    return { handleClearInterval, startTimer, stopTimer, pauseTimer, time }
}

export {
    useRenderRaceAnimation,
    useSequentialAnimationTimers,
    useSequentialAnimationTimersV4,
    useSequentialAnimationTimersV3,
    useIntervalWhen,
    useIntervalWhenControl,
}
