import React, { useEffect } from 'react'
import { motion, useAnimate } from 'framer-motion'
export const NoiseFilter = React.forwardRef<HTMLDivElement, { id: string }>(
    (props, ref) => {
        return (
            <motion.div
                id={props.id}
                ref={ref}
                className='noise-bg absolute left-0 top-0 inset-0 z-30'
            />
        )
    },
)

NoiseFilter.displayName = 'NoiseFilter'

export const CheckersFilter = React.forwardRef<HTMLDivElement, { id: string }>(
    (props, ref) => {
        return (
            <motion.div
                id={props.id}
                ref={ref}
                className='absolute left-0 top-0 inset-0 z-40 opacity-100'
                style={{
                    backgroundImage: `url('/images/assets/checkers-dark.png')`,
                }}
            />
        )
    },
)

CheckersFilter.displayName = 'CheckersFilter'

export const PlayFilter = React.forwardRef<HTMLDivElement, { id: string }>(
    (props, ref) => {
        return (
            <motion.div
                onViewportEnter={() => {
                    console.log('scrolled to view')
                }}
                id={props.id}
                ref={ref}
                onClick={() => {
                    console.log('play outside')
                }}
                className='cursor-pointer absolute left-0 top-0 bg-neutral-900 inset-0 z-50'
            >
                <button
                    onClick={() => {
                        console.log('play')
                    }}
                    className='cursor-pointer absolute bg-slate-400 left-1/2 top-1/2 translate-x-1/2 translate-y-1/2'
                >
                    Play
                </button>
            </motion.div>
        )
    },
)
