import React from 'react'
import { motion } from 'framer-motion'
import { capitalize } from 'lodash-es'
import { formatElapsedTime } from '@/components/blocks/render-race/utils'
type AnimationStatus = 'raytracing' | 'denoising' | 'complete'
import { cn } from '@/lib/utils'
import { AnimatePresence } from 'framer-motion'

export const CompleteStatus = ({
    timeToComplete,
    className,
}: { timeToComplete: string; className?: string }) => (
    <div className={cn('flex flex-col', className)}>
        <span className='inline-block px-0 bg-clip-text text-transparent bg-gradient-to-r from-green-200 to-green-400'>
            Completed
        </span>
        <span className='inline-block px-0 text-neutral-200 text-sm'>
            {timeToComplete}
        </span>
    </div>
)

export const DefaultStatus = ({ status }: { status: AnimationStatus }) => {
    return (
        <span className='inline-block px-0 animate-pulse bg-clip-text text-transparent bg-gradient-to-r from-neutral-200 to-neutral-400'>
            {capitalize(status)}
        </span>
    )
}

export const Status = ({
    status,
    timeToComplete,
    left,
}: { status: AnimationStatus; timeToComplete: string; left: boolean }) => {
    const className = left ? 'items-start' : 'items-end'
    return (
        <AnimatePresence mode='popLayout' initial={false}>
            <motion.h2
                className='text-center text-white span'
                initial={{ opacity: 0, y: -25 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 25 }}
                transition={{ type: 'spring', duration: 0.3, bounce: 0.1 }}
                key={`${status}-${left}`}
            >
                {status === 'complete' ? (
                    <CompleteStatus
                        className={className}
                        timeToComplete={timeToComplete}
                    />
                ) : (
                    <DefaultStatus status={status} />
                )}
            </motion.h2>
        </AnimatePresence>
    )
}

export const Timer = ({ time }: { time: number }) => {
    return (
        <>
            <h2 className='text-center text-indigo-300'>Time Elapsed:</h2>
            <p className='text-sm justify-self-center'>
                {time !== null ? formatElapsedTime(time) : '00h00m00s'}
            </p>
        </>
    )
}
