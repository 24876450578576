import { twMerge } from "tailwind-merge";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
/**
 * @description A direction aware tabs component adapted from Lukacho UI's `NavBar` Component
 * from docs:
 * you handle routing logic. Code is not complex, just play with it and you gonna figure out how it works.
 */
export const DirectionAwareTabs: React.FC<{
	tabs: string[];
	onTabChange: (index: number) => void;
	style: React.CSSProperties;
	scrollPercentage: number;
}> = ({ tabs, onTabChange, style, scrollPercentage }) => {
	const fired = useRef(true);
	const defaultSelectedTabIndex = 0;
	const [currentLink, setCurrentLink] = useState<{
		index: number;
		left: undefined | number;
		width: undefined | number;
	}>({
		index: defaultSelectedTabIndex,
		left: undefined,
		width: undefined,
	});

	/**
	 * TailwindCSS scans your codebase and based on that generates styles
	 * TailwindCSS does not allow to concatenate class names, so just wrote down all possible combinations (you can add more if you need, you got the idea)
	 * read https://tailwindcss.com/docs/content-configuration#dynamic-class-names
	 * you can not do like this - `[&:nth-child(${child})]:bg-neutral-950` it won't work
	 */
	const defaultSelectedTabStyles = [
		"[&:nth-child(1)]:bg-white text-neutral-500",
		"[&:nth-child(2)]:bg-white text-neutral-300",
		"[&:nth-child(3)]:bg-white text-neutral-300",
		"[&:nth-child(4)]:bg-white text-neutral-300",
	];

	useEffect(() => {
		setCurrentLink(() => ({
			left: document.getElementById("uuu-btn-" + defaultSelectedTabIndex)
				?.offsetLeft,
			width: document
				.getElementById("uuu-btn-" + defaultSelectedTabIndex)
				?.getBoundingClientRect().width,
			index: defaultSelectedTabIndex,
		}));
	}, []);

	return (
		<motion.div
			className={`relative mb-3 flex w-fit items-center justify-center gap-5 rounded-full border p-2 backdrop-blur-2xl border-neutral-800`}
			style={style}
		>
			{tabs.map((link, i) => {
				return (
					<button
						key={i}
						id={"uuu-btn-" + i}
						onClick={() => {
							fired.current = true;
							setCurrentLink(() => ({
								left: document.getElementById("uuu-btn-" + i)?.offsetLeft,
								width: document
									.getElementById("uuu-btn-" + i)
									?.getBoundingClientRect().width,
								index: i,
							}));
							onTabChange(i);
					}}
					className={twMerge(
						"flex h-fit items-center justify-center text-nowrap rounded-full px-2 py-2 transition-colors duration-200 text-neutral-200",
						currentLink.index === i && "text-neutral-950",
						fired.current
							? ""
							: defaultSelectedTabStyles[defaultSelectedTabIndex],
					)}
				>
					{link}
					</button>
				);
			})}
			<div className={"absolute inset-0 -z-[1] h-full overflow-hidden p-2"}>
				<div className={"relative h-full w-full overflow-hidden"}>
					<div
						style={{
							left: `calc(${currentLink.left || 0}px - 0.75rem + 0.25rem)`,
							width: `${currentLink.width || 0}px`,
						}}
						className={twMerge(
							`bg-white text-neutral-950 absolute top-1/2 -z-[1] h-full -translate-y-1/2 rounded-full transition-[color,left,width] duration-300`,
						)}
					/>
				</div>
			</div>
		</motion.div>
	);
};
