import { cn } from '@/lib/utils'
import { motion, useInView } from 'framer-motion'
import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'

//TODO: consider "Unbeatable speed" as a title
export const ShinedVersusText = () => {
    return (
        <div className='isolate'>
            <motion.h1
                initial={{ opacity: 0.4, y: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                    delay: 0.0,
                    duration: 0.6,
                    ease: 'easeInOut',
                }}
                className='text-center bg-gradient-to-b from-indigo-300 to-indigo-700 bg-clip-text py-4 text-5xl font-medium tracking-tight text-transparent md:text-7xl lg:text-7xl z-20'
                key={'unbeatable-speeds'}
            >
                Unbeatable speeds.
            </motion.h1>
            <div className='flex justify-between'>
                <motion.h1
                    initial={{ opacity: 0.4, y: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.2,
                        duration: 0.7,
                        ease: 'easeInOut',
                    }}
                    key={'your-computer'}
                    className='bg-gradient-to-b from-slate-100 to-slate-500 bg-clip-text py-4 text-2xl font-medium tracking-tight text-transparent md:text-3xl z-10'
                >
                    Your Computer
                </motion.h1>
                <motion.h1
                    initial={{ opacity: 0.4, y: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.2,
                        duration: 0.4,
                        ease: 'easeInOut',
                    }}
                    className='bg-gradient-to-b from-slate-100 to-indigo-400 bg-clip-text py-4 text-2xl font-medium tracking-tight text-transparent md:text-3xl z-10'
                    key={'renderjuice'}
                >
                    Renderjuice
                </motion.h1>
            </div>
        </div>
    )
}

/**
 * Lamp Container
 * @description - Lamp header, adopted from Aceternity UI. Should generally be used with `client:load` to enable animations.
 * The outer container (A) is at minimum equal to height of screen and is relatively positioned.
 * The inner container (B) that actually holds all the lamp elements *must* be able to shine through this container which makes it tricky.
 * Thus, the inner container (B) gets an isolated stacking context and its elements are then positioned absolutely to the inner container (B).
 *
 * The `scale` is used to make the lamp elements responsive to the device size.
 *
 */
export const LampContainer = ({
    children,
    className,
}: {
    children?: React.ReactNode
    className?: string
}) => {
    return (
        <>
            <div
                className={cn(
                    'relative z-0 flex w-full flex-col items-center rounded-md overflow-x-hidden bg-black pt-[300px]',
                    className,
                )}
            >
                <div className='relative isolate z-0 flex w-full scale-x-75 scale-y-100 items-center justify-center md:scale-x-100'>
                    <div className='absolute top-1/2 h-48 w-full translate-y-12 scale-x-150 bg-black blur-2xl'></div>
                    <div className='absolute top-1/2 z-50 h-48 w-full bg-transparent opacity-10 backdrop-blur-md'></div>
                    <div className='absolute inset-auto z-50 h-36 w-[28rem] -translate-y-1/2 rounded-full bg-violet-500 opacity-50 blur-3xl'></div>

                    <motion.div
                        initial={{ opacity: 0.5, width: '15rem' }}
                        whileInView={{ opacity: 1, width: '30rem' }}
                        transition={{
                            delay: 0.3,
                            duration: 0.8,
                            ease: 'easeInOut',
                        }}
                        style={{
                            backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
                        }}
                        className='bg-gradient-conic absolute inset-auto right-1/2 h-56 w-[30rem] overflow-visible from-indigo-500 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]'
                    >
                        <div className='absolute bottom-0 left-0 z-20 h-40 w-[100%] bg-black [mask-image:linear-gradient(to_top,white,transparent)]' />
                        <div className='absolute bottom-0 left-0 z-20 h-[100%] w-40 bg-black [mask-image:linear-gradient(to_right,white,transparent)]' />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0.5, width: '15rem' }}
                        whileInView={{ opacity: 1, width: '30rem' }}
                        transition={{
                            delay: 0.3,
                            duration: 0.8,
                            ease: 'easeInOut',
                        }}
                        style={{
                            backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
                        }}
                        className='bg-gradient-conic absolute inset-auto left-1/2 h-56 w-[30rem] from-transparent via-transparent to-indigo-500 text-white [--conic-position:from_290deg_at_center_top]'
                    >
                        <div className='absolute bottom-0 right-0 z-20 h-[100%] w-40 bg-black [mask-image:linear-gradient(to_left,white,transparent)]' />
                        <div className='absolute bottom-0 right-0 z-20 h-40 w-[100%] bg-black [mask-image:linear-gradient(to_top,white,transparent)]' />
                    </motion.div>
                    <motion.div
                        initial={{ width: '8rem' }}
                        whileInView={{ width: '16rem' }}
                        transition={{
                            delay: 0.2,
                            duration: 0.4,
                            ease: 'easeInOut',
                        }}
                        className='absolute inset-auto z-30 h-36 w-64 -translate-y-[6rem] rounded-full bg-indigo-300 blur-2xl'
                    ></motion.div>
                    <motion.div
                        initial={{ width: '15rem' }}
                        whileInView={{ width: '30rem' }}
                        transition={{
                            delay: 0.2,
                            duration: 0.4,
                            ease: 'easeInOut',
                        }}
                        className='absolute inset-auto z-50 h-0.5 w-[30rem] -translate-y-[7rem] bg-indigo-400'
                    ></motion.div>
                    <div className='absolute inset-auto z-40 h-44 w-full -translate-y-[12.5rem] bg-black'></div>
                </div>
                <div className='flex-col'>{children}</div>
            </div>
        </>
    )
}
